/*
 * STENIK SUPER SIMPLE COUNTDOWN TIMER
 * VERSION: 1.1
 * DATE: 21-01-2015
 * @license Copyright(c) StenikGroup LTD. All rights reserved.
 * @author: gani@stenik.bg
 * -------------------------------------------------------------------
 * OPTIONS:
 * -- toDate => set counter end date in format '02/20/2015 18:00:00'
 * -------------------------------------------------------------------
 * METHODS: 
 * -- init    => plugin init
 * -- destroy => plugin inst remove and DOM cleanup
 * -------------------------------------------------------------------
 * CALLBACKS:         
 * -- onInit         => triggered on init
 * -- onCountdown    => triggered on every 'tick'
 * -- onCountdownEnd => triggered after time ran out
 */
const Modernizr = require("modernizr");
;(function($) {
	var defaults = {
		toDate: '',
		texts: {
			minutes: 'Мин.',
			seconds: 'Сек.'
		},
		onInit: function() {},
		onCountdown: function(timeObj) {},
		onCountdownEnd: function() {}
	};
	var settings = {};

	// PRIVATE FUNCTIONS
	// --- IE console.log fix
	function stenikLog(msg) {
		var console = window.console;
		if (console && typeof console.log === 'function') {
			console.log(msg);
		}
	}
	// --- date validation
	function isValidDate(testDate) {
		// An invalid date object returns NaN for getTime()
		// and NaN is the only object not strictly equal to itself.
		// >>> see benchmark test on http://jsperf.com/detecting-an-invalid-date
		var testDateTime = testDate.getTime();
		return testDateTime === testDateTime;
	}
	// --- svg progress update
	function progressUpdate(obj, value) {
		if(Modernizr.svg && obj && obj.circle && obj.path) {
			var old_cx = obj.circle.attr('cx');
			var old_cy = obj.circle.attr('cy');
			
			var progress = obj.length - (value*obj.length);
			// path update
			obj.path.style.strokeDashoffset = progress;
			// point update
			var myPoint = obj.path.getPointAtLength(obj.length - progress);

			obj.circle.attr('cx', myPoint.x);
			obj.circle.attr('cy', myPoint.y);
		}
	}

	// METHODS
	var methods = {
		init: function(options) {
			return this.each(function() {
				methods.destroy.call(this, options); // on reinit fix

				var $this = $(this);
				settings = $this.data('stenikCountdownTimer');
				if(typeof(settings) == 'undefined') {
					settings = $.extend({}, defaults, options);
					$this.data('stenikCountdownTimer', settings);
				} else {
					settings = $.extend({}, settings, options);
				}

				// Cache purposes
				var $wrapper, $secondsBox, $minutesBox, toDate, fromDate,
					diff_in_seconds, timer, printMin, printSec;
				var returnedTime = {};

				toDate   = new Date(settings.toDate);
				fromDate = new Date(); // from now
				if(isValidDate(toDate)) {
					// Wrapper generate
					$this.append('<div class="stenikCountdownTimer">' +
									'<div class="stenikTimerCounter minutesCountdown">' +
								 		'<div class="counter-minutes"></div>' +
										'<div class="txt">'+settings.texts.minutes+'</div>' +
								 	'</div>' +
									'<div class="stenikTimerCounter secondsCountdown">' +
								 		'<div class="counter-seconds"></div>' +
										'<div class="txt">'+settings.texts.seconds+'</div>' +
								 	'</div>'+
									'<div class="delimiter">:</div>'+
								'</div>');
					$wrapper     = $this.find('.stenikCountdownTimer');
					$secondsBox  = $wrapper.find('.counter-seconds');
					$minutesBox = $wrapper.find('.counter-minutes');

					// SVG circles progress generate
					$wrapper.append('<svg xmlns="http://www.w3.org/2000/svg" class="stenikBgSVG" width="130" height="130">' +
										'<circle cx="65" cy="65" r="62" fill="none" />' +
									'</svg>' +
									'<svg xmlns="http://www.w3.org/2000/svg" id="minutes_svg" class="stenikTimerSVG minutesSVG" width="62" height="62" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">' +
										'<path class="minutes_progress_path progressPath" d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98 "/>' +
										'<circle class="minutes_path_end_circle pathEndCircle" cx="50" cy="50" r="5" fill="#fff" />' +
										'</svg>' +
									'<svg xmlns="http://www.w3.org/2000/svg" id="seconds_svg" class="stenikTimerSVG secondsSVG" width="62" height="62" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">' +
										'<path class="seconds_progress_path progressPath" d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98 "/>' +
										'<circle class="seconds_path_end_circle pathEndCircle" cx="50" cy="50" r="5" fill="#fff" />' +
									'</svg>');
					var $secondsCircle        = $wrapper.find('.secondsSVG');
					var $secondsPathEndCircle = $('.seconds_path_end_circle');
					var secondsProgressPath   = $('.seconds_progress_path').get(0);
					var $minutesCircle        = $wrapper.find('.minutesSVG');
					var	$minutesPathEndCircle = $('.minutes_path_end_circle');
					var minutesProgressPath   = $('.minutes_progress_path').get(0);
					var secondsPathLength, minutesPathLength;

					// >>> paths definitions
					if(Modernizr.svg) {
						secondsPathLength = secondsProgressPath.getTotalLength();
						secondsProgressPath.style.strokeDasharray = secondsPathLength + ' ' + secondsPathLength;
						secondsProgressPath.style.strokeDashoffset = secondsPathLength;
						secondsProgressPath.getBoundingClientRect();

						minutesPathLength = minutesProgressPath.getTotalLength();
						minutesProgressPath.style.strokeDasharray = minutesPathLength + ' ' + minutesPathLength;
						minutesProgressPath.style.strokeDashoffset = minutesPathLength;
						minutesProgressPath.getBoundingClientRect();
					}
					var secondsPathObj = {
						length: secondsPathLength,
						path: secondsProgressPath,
						circle: $secondsPathEndCircle
					};
					var minutesPathObj = {
						length: minutesPathLength,
						path: minutesProgressPath,
						circle: $minutesPathEndCircle
					};
					if (!Date.now) Date.now = function () {return +new Date();}; // IE8 Date.now fix

					// Timer actions
					diff_in_seconds = ((toDate - fromDate)/1000) << 0;
					diff_in_minutes = diff_in_seconds / 60;
					var	interval = 150,
						base = 1000; // 1 second,
						step = interval/base,
						min  = (diff_in_seconds / 60) << 0,
						sec  = diff_in_seconds - min * 60;

					// Helper vars for the additional circle svg counters (have been added later)
					var csec = sec,
						cmin = diff_in_seconds/60;

					var now = Date.now();
					timer = setInterval(function() {
						if(diff_in_seconds >= 0) {
							base -= interval;
							if(base <= 0) {
								now = Date.now();
								diff_in_seconds--;
								diff_in_seconds = ((toDate - Date.now())/1000 - 1) << 0;

								// pretty print
								min = (diff_in_seconds / 60) << 0; // used in text counter
								sec = diff_in_seconds%60; // used in text counter
								printMin = min;
								printSec = sec;
								if(min < 10) printMin = '0' + min;
								if(sec < 10) printSec = '0' + sec;
								$minutesBox.html(printMin);
								$secondsBox.html(printSec);
								// stenikLog('Booking time left: ' + printMin + ':' + printSec);

								// callback trigger
								returnedTime = {
									min: min,
									sec: sec
								}
								settings.onCountdown.call(this, returnedTime);

								// base reset
								base = 1000;
							}

							// circles update
							if(csec >= 0) {
								csec -= step;
								progressUpdate(secondsPathObj, csec / 60); // seconds circle counter

								cmin -= (step/60);
								if((cmin-1) >= 0) {
									progressUpdate(minutesPathObj, (cmin-1) / diff_in_minutes); // minutes circle counter
								} else {
									progressUpdate(minutesPathObj, 0);
								}
							} else {
								csec = 60; // seconds reset
							}
						} else {
							// time ran out
							clearInterval(timer);
							$minutesBox.html('00');
							$secondsBox.html('00');
							progressUpdate(secondsPathObj, 0);
							progressUpdate(minutesPathObj, 0);
							settings.onCountdownEnd.call(this);
							// stenikLog('Booking time ran out :(');
						}
						before = Date.now();
					}, interval);
				} else {
					stenikLog('Invalid date format!');
				}
				settings.onInit.call(this);
			});
		},
		destroy: function() {
			return $(this).each(function() {
				var $this = $(this);

				$this.removeData('stenikCountdownTimer');
				$wrapper = $this.find('.stenikCountdownTimer');
				if($wrapper.length)
					$wrapper.remove();
			});
		}
	};
	$.fn.stenikCountdownTimer = function() {
		var method = arguments[0];
		if(methods[method]) {
			method = methods[method];
			arguments = Array.prototype.slice.call(arguments, 1);
		} else if( typeof(method) == 'object' || !method ) {
			method = methods.init;
		} else {
			$.error( 'Method ' +  method + ' does not exist on jQuery.stenikCountdownTimer' );
			return this;
		}
		return method.apply(this, arguments);
	}
})(jQuery);